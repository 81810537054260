export const environment = {
  production: true,
  title: "[DEV] Medicinae Solutions - Organização",
  name: 'dev',
  api: {
    client_id: "2",
    client_secret: "dhGLOnQlLZhDPJmRaNXXJcAOEcDZnqMtm7VGpc5t",
    base_url: "https://dev.api.iclinicpay.com.br",
    base_api: "https://dev.api.iclinicpay.com.br/api/v1/",
    spreadsheet_importer_api:
      "https://spreadsheet-importer-api.medicinaesolutions.com/dev",
    spreadsheet_importer_ws_api:
      "wss://spreadsheet-importer-ws-api.medicinaesolutions.com/dev"
  },
  assignmentTermRequireGuarantorValue: 200000
};
